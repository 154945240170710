@import (reference) '../../../styles/globals.less';

.preview-banner {
  background: var(--token-color-blue-b90);
  color: var(--token-color-blue-b40);
  height: 36px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
