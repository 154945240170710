@import (reference) './globals.less';

.layout {
  max-width: @screen-xl;
  margin: 0 auto;
  padding: 16px 32px;
}

.homePOCGrid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

.theme-dark {
  .dark-theme();
  background-color: #030c16;
}

.theme-light {
  .light-theme();
  background-color: #ffffff;
}
